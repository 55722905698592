<template>
  <b-card>
    <h2 class="text-blue font-weight-bold justify-content-start mb-2">Edit User</h2>
    <validation-observer >
      <!-- user suggestion  -->

      <b-form>
        <b-row v-if="!showLoader">
          <b-col md="6">
            <b-form-group
                id="profile-email-address"
                :label="$t('email') + ':'"
                label-for="profile-email-address"
            >
              <b-form-input
                  id="profile-email-address"
                  v-model.trim="user.email"
                  disabled
                  type="email"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-group
                id="main-contact-tel-group"
                :label="$t('mobile_phone') + ':'"
                label-for="main-contact-tel"
            >
              <validation-provider
                  #default="{ errors }"
                  name="main-contact-tel"
              >
                <b-input-group>
                  <b-input-group-prepend id="phoneCountries">
                    <b-form-select
                        :options="phoneCountriesList"
                        v-model.trim="user.iso_code"
                        class="form-control"
                        :state="errors.length > 0 ? false : null"
                    >
                    </b-form-select>
                  </b-input-group-prepend>
                  <b-form-input
                      type="number"
                      id="main-contact-tel"
                      v-model.trim="user.tel"
                      @input="checkPhoneFormat"
                      class="form-control"
                      :state="errors.length > 0 ? false : null"
                  >
                  </b-form-input>
                </b-input-group>

                <small class="text-danger">{{ $t(errors[0]) }}</small>
                <small class="text-danger" v-if="phoneValidation">{{
                    phoneValidation
                  }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
                id="main-contact-gender-group"
                :label="$t('gender') + ':'"
                label-for="main-contact-gender"
            >
              <b-form-select
                  id="main-contact-gender"
                  v-model.trim="user.gender"
                  :options="[
                  {
                    text: $t('select_one'),
                    value: null,
                    disabled: true,
                  },
                  { text: $t('mr'), value: '0' },
                  { text: $t('mrs'), value: '1' },
                ]"
                  required
              ></b-form-select>
            </b-form-group>
            <b-form-group
                id="profile-first-name"
                :label="$t('first_name') + ':'"
                label-for="profile-first-name"
            >
              <b-form-input
                  id="profile-first-name"
                  v-model.trim="user.first_name"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-group
                id="profile-last-name"
                :label="$t('last_name') + ':'"
                label-for="profile-last-name"
            >
              <b-form-input
                  id="profile-last-name"
                  v-model.trim="user.last_name"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="profile-birthday"
                :label="$t('birthday') + ':'"
                label-for="profile-birthday"
            >
              <b-form-datepicker
                  v-model.trim="user.birthday"
                  id="profile-birthday"
                  no-flip
                  required
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                id="company-address-group"
                :label="$t('address') + ':'"
                label-for="company-address"
            >
              <validation-provider #default="{ errors }" name="Company Address">
                <b-form-input
                    id="company-address"
                    v-model.trim="user.address"
                    :state="errors.length > 0 ? false : null"
                    required
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
                id="company-address-nr-group"
                :label="$t('address_nr') + ':'"
                label-for="company-address-nr"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Company Address Nr"
              >
                <b-form-input
                    id="company-address-nr"
                    v-model.trim="user.address_nr"
                    required
                    :state="errors.length > 0 ? false : null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
                id="company-zip-group"
                :label="$t('zip') + ':'"
                label-for="company-zip"
            >
              <validation-provider #default="{ errors }" name="Company Zip">
                <b-form-input
                    id="company-zip"
                    v-model.trim="user.zip"
                    required
                    :state="errors.length > 0 ? false : null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
                id="company-city-group"
                :label="$t('city') + ':'"
                label-for="company-city"
            >
              <validation-provider #default="{ errors }" name="Company City">
                <b-form-input
                    id="company-city"
                    v-model.trim="user.city"
                    required
                    :state="errors.length > 0 ? false : null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
                id="domicile-country-group"
                :label="$t('domicile_country') + ':'"
                label-for="domicile-country"
            >
              <validation-provider #default="{ errors }" name="Country">
                <b-form-select
                    id="domicile-country"
                    v-model.trim="user.country_id"
                    :options="countries"
                    :state="errors.length > 0 ? false : null"
                    required
                    :disabled="disableInputs"
                ></b-form-select>
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
                id="profile-sum-sub"
                :label="'SumSub ID:'"
                label-for="profile-sum-sub"
            >
              <b-form-input
                  id="profile-sum-sub"
                  v-model.trim="user.sumsub_id"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-col cols="12" v-if="showLoader">
          <img src="/loader.svg" />
          <!-- {{ JSON.stringify(company_zefix_data) }} -->
        </b-col>

        <b-row class="col-md-12 text-end justify-content-end p-0 pr-0 m-0">
          <b-button
              type="button"
              variant="outline-primary"
              class="outlined mr-2"
              @click="cancelEdit"
          >{{ $t("close") }}
          </b-button>
          <b-button @click="editForm()" type="button" variant="primary"
          >{{ $t("edit") }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>

    <!--/ user suggestion  -->
  </b-card>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { phone } from "phone";
import moment from "moment";
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      user: {
        name: "",
        last_name: "",
        birthday: "",
        email: "",
        gender: "",
        position: "",
        tel: "",
        address: "",
        address_nr: "",
        city: "",
        zip: "",
        country_id: "",
        country_name: "",
        nationality: "",
        language: "German",
        sumsub_id: "",
      },
      disableInputs: false,
      show: true,
      showLoader: true,
      countries: [],
      phoneContries: "CH",
      phoneValidation: '',
      phoneCountriesList: [],
      countries_code: [],
      userId: null,
      title: 'update-user',
      url: '/user/admin/show',
      options: null,
      isLoading: false,

    }
  },
  async created() {
    this.userId = this.$route.params.id

    await this.$http.post("/countries").then((res) => {
      res.data.value.map((item) => {
        this.countries_code.push(item);
        this.phoneCountriesList.push({
          value: item.iso_code,
          text: `${item.iso_code} (${item.phone_code})`,
        });
        this.countries.push({
          value: item.id,
          text: item.name,
        });
      });


      this.getApiData()
    });

  },
  methods: {
    getApiData() {
      this.items = []
      this.isLoading = true
      this.$http
        .get(`${this.url}/${this.userId}`)
        .then(response => {
          if (response.data.status == 200) {
            this.user = response.data.value
            this.getData();
          }
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    },
    getData() {
      this.user.birthday = moment(
          this.user.birthday,
          "DD.MM.YYYY"
      ).format("YYYY-MM-DD")

      this.user.tel = this.getPhone(this.user.iso_code, this.user.tel);
      this.showLoader = false
    },
    getPhone(iso, tel) {
      if (this.countries_code && this.countries_code.length) {
        this.countries_code.map((i, item) => {
          if (i.iso_code == iso && tel != null) {
            tel = tel.replace(i.phone_code, "");
          }
        });
      }
      return tel;
    },
    setDate() {
      /* Increase day by 1 */
      const newDate = new Date(this.user.birthday);
      newDate.setDate(newDate.getDate() + 1);

      this.user.birthday = newDate;
    },
    checkPhoneFormat() {
      let validation = phone(this.user.tel, {
        country: this.user.iso_code,
        validateMobilePrefix: false
      });

      if (validation && validation.isValid == false) {
        this.phoneValidation = `Phone for ${this.user.iso_code} must be valid`;
      } else {
        this.phoneValidation = "";
      }
    },
    // searchForCompaniesName() {
    //   if (this.user.nationality.length > 2) {
    //     this.$http
    //         .post("/get-companies-name-from-zefix", {
    //           search: this.user.nationality,
    //         })
    //         .then((res) => {
    //           this.companies = res.data.value;
    //         });
    //   }
    // },
    // getCompanyDataFromZefix() {
    //   this.showLoader = true;
    //   if (typeof this.companies[this.user.nationality] != "undefined") {
    //     this.$http
    //         .post("/get-company-data-from-zefix", {
    //           search: this.companies[this.user.nationality],
    //         })
    //         .then((res) => {
    //           this.showLoader = false;
    //         });
    //   }
    // },
    editForm() {
      this.showLoader = true
      this.$http.post(`/user/admin/store/${this.userId}`, this.user).then((res) => {
        if (res) {
          this.getData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "User updated successfully",
              icon: "SuccessIcon",
              variant: "success",
            },
          });
        }
      });
      router.push('/users');
      // this.$http.post("/update/language", {language: data.language});
    },
    cancelEdit() {
      router.push('/users')
    },

  },

  computed: {
    // company() {
    //   return this.$store.state.app.watchCompany.value;
    // },
  },
}
</script>

<style scoped>
</style>
