var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h2',{staticClass:"text-blue font-weight-bold justify-content-start mb-2"},[_vm._v("Edit User")]),_c('validation-observer',[_c('b-form',[(!_vm.showLoader)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"profile-email-address","label":_vm.$t('email') + ':',"label-for":"profile-email-address"}},[_c('b-form-input',{attrs:{"id":"profile-email-address","disabled":"","type":"email","required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.email"}})],1),_c('b-form-group',{attrs:{"id":"main-contact-tel-group","label":_vm.$t('mobile_phone') + ':',"label-for":"main-contact-tel"}},[_c('validation-provider',{attrs:{"name":"main-contact-tel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"id":"phoneCountries"}},[_c('b-form-select',{staticClass:"form-control",attrs:{"options":_vm.phoneCountriesList,"state":errors.length > 0 ? false : null},model:{value:(_vm.user.iso_code),callback:function ($$v) {_vm.$set(_vm.user, "iso_code", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.iso_code"}})],1),_c('b-form-input',{staticClass:"form-control",attrs:{"type":"number","id":"main-contact-tel","state":errors.length > 0 ? false : null},on:{"input":_vm.checkPhoneFormat},model:{value:(_vm.user.tel),callback:function ($$v) {_vm.$set(_vm.user, "tel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.tel"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))]),(_vm.phoneValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.phoneValidation))]):_vm._e()]}}],null,false,752024464)})],1),_c('b-form-group',{attrs:{"id":"main-contact-gender-group","label":_vm.$t('gender') + ':',"label-for":"main-contact-gender"}},[_c('b-form-select',{attrs:{"id":"main-contact-gender","options":[
                {
                  text: _vm.$t('select_one'),
                  value: null,
                  disabled: true,
                },
                { text: _vm.$t('mr'), value: '0' },
                { text: _vm.$t('mrs'), value: '1' } ],"required":""},model:{value:(_vm.user.gender),callback:function ($$v) {_vm.$set(_vm.user, "gender", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.gender"}})],1),_c('b-form-group',{attrs:{"id":"profile-first-name","label":_vm.$t('first_name') + ':',"label-for":"profile-first-name"}},[_c('b-form-input',{attrs:{"id":"profile-first-name","required":""},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.first_name"}})],1),_c('b-form-group',{attrs:{"id":"profile-last-name","label":_vm.$t('last_name') + ':',"label-for":"profile-last-name"}},[_c('b-form-input',{attrs:{"id":"profile-last-name","required":""},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.last_name"}})],1),_c('b-form-group',{attrs:{"id":"profile-birthday","label":_vm.$t('birthday') + ':',"label-for":"profile-birthday"}},[_c('b-form-datepicker',{attrs:{"id":"profile-birthday","no-flip":"","required":""},model:{value:(_vm.user.birthday),callback:function ($$v) {_vm.$set(_vm.user, "birthday", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.birthday"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"company-address-group","label":_vm.$t('address') + ':',"label-for":"company-address"}},[_c('validation-provider',{attrs:{"name":"Company Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-address","state":errors.length > 0 ? false : null,"required":"","disabled":_vm.disableInputs},model:{value:(_vm.user.address),callback:function ($$v) {_vm.$set(_vm.user, "address", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,2377232673)})],1),_c('b-form-group',{attrs:{"id":"company-address-nr-group","label":_vm.$t('address_nr') + ':',"label-for":"company-address-nr"}},[_c('validation-provider',{attrs:{"name":"Company Address Nr"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-address-nr","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.user.address_nr),callback:function ($$v) {_vm.$set(_vm.user, "address_nr", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.address_nr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,949888947)})],1),_c('b-form-group',{attrs:{"id":"company-zip-group","label":_vm.$t('zip') + ':',"label-for":"company-zip"}},[_c('validation-provider',{attrs:{"name":"Company Zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-zip","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.user.zip),callback:function ($$v) {_vm.$set(_vm.user, "zip", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,3103108769)})],1),_c('b-form-group',{attrs:{"id":"company-city-group","label":_vm.$t('city') + ':',"label-for":"company-city"}},[_c('validation-provider',{attrs:{"name":"Company City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-city","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.user.city),callback:function ($$v) {_vm.$set(_vm.user, "city", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,3166202305)})],1),_c('b-form-group',{attrs:{"id":"domicile-country-group","label":_vm.$t('domicile_country') + ':',"label-for":"domicile-country"}},[_c('validation-provider',{attrs:{"name":"Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"domicile-country","options":_vm.countries,"state":errors.length > 0 ? false : null,"required":"","disabled":_vm.disableInputs},model:{value:(_vm.user.country_id),callback:function ($$v) {_vm.$set(_vm.user, "country_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,4195180686)})],1),_c('b-form-group',{attrs:{"id":"profile-sum-sub","label":'SumSub ID:',"label-for":"profile-sum-sub"}},[_c('b-form-input',{attrs:{"id":"profile-sum-sub","required":""},model:{value:(_vm.user.sumsub_id),callback:function ($$v) {_vm.$set(_vm.user, "sumsub_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.sumsub_id"}})],1)],1)],1):_vm._e(),(_vm.showLoader)?_c('b-col',{attrs:{"cols":"12"}},[_c('img',{attrs:{"src":"/loader.svg"}})]):_vm._e(),_c('b-row',{staticClass:"col-md-12 text-end justify-content-end p-0 pr-0 m-0"},[_c('b-button',{staticClass:"outlined mr-2",attrs:{"type":"button","variant":"outline-primary"},on:{"click":_vm.cancelEdit}},[_vm._v(_vm._s(_vm.$t("close"))+" ")]),_c('b-button',{attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.editForm()}}},[_vm._v(_vm._s(_vm.$t("edit"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }