<template>
  <div>
    <UserUpdateForm v-if="$route.params.id"/>
    <UsersList v-else />
  </div>
</template>

<script>
import UsersList from "@/components/Users/UsersList";
import UserUpdateForm from '@/components/Users/UserUpdateForm.vue';
export default {
  name: "Users",
  components: {
    UsersList,
    UserUpdateForm
  },
  data() {
    return {
    };
  },

  methods: {},
};
</script>

<style scoped></style>
